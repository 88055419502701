import * as React from "react";
import { useEffect, useState } from "react";
import loadable from '@loadable/component'
import '../styles/global.scss'
import { paragraphContent } from "../utils/informations";
const Layout = loadable(() => import("../layouts"))
const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
const Banner = loadable(() => import("../components/banner/banner"))


const IndexPage = () => {

  const [showElement, setShowElement] = useState(false)
  useEffect(() => {
    setTimeout(function () {

      setShowElement(true)
    }, 1000);
  },
    [])

  return (

    <Layout pageTitle="My Policy Vault">

        <Paragraph props={paragraphContent} origin="HomepageHeader"/>
        <Paragraph props={paragraphContent} origin="HomepageContent1"/>
        <Paragraph props={paragraphContent} origin="HomepageContent2"/>
        <Paragraph props={paragraphContent} origin="HomepageContent3"/>

    </Layout>

  )
};

export default IndexPage;
